import api from '../httpServer'
import config from '../config'

// 权限管理的接口
const rights ={
    /*
    * 菜单目录
    * */
    // 菜单
    addModuleList(data) {
        return api.post(`${config.headerUrl}modulelist/addModuleList`,data)
    },
    deleteModuleList(data) {
        return api.post(`${config.headerUrl}modulelist/deleteModuleList`,data)
    },
    editModuleList(data) {
        return api.post(`${config.headerUrl}modulelist/editModuleList`,data)
    },
    getModuleDetail(data) {
        return api.get(`${config.headerUrl}modulelist/getModuleDetail`,data)
    },
    getModuleList(data) {
        return api.get(`${config.headerUrl}modulelist/getModuleList`,data)
    },
    getModuleListByPage(data) {
        return api.get(`${config.headerUrl}modulelist/getModuleListByPage`,data)
    },
    /*
    * 角色信息
    * */
    // 新增角色信息
    addRole(data) {
        return api.post(`${config.headerUrl}role/addRole`,data)
    },
    // 删除角色信息
    deleteRole(data) {
        return api.post(`${config.headerUrl}role/deleteRole`,data)
    },
    // 修改角色信息
    editRole(data) {
        return api.post(`${config.headerUrl}role/editRole`,data)
    },
    // 角色分页列表
    getListByPage(data) {
        return api.get(`${config.headerUrl}role/getListByPage`,data)
    },
    // 角色详情
    getRoleDetail(data) {
        return api.get(`${config.headerUrl}role/getRoleDetail`,data)
    },
    // 角色列表
    getRoleList(data) {
        return api.get(`${config.headerUrl}role/getRoleList`,data)
    },
    /*
    * 角色权限
    * */
    addRoleAuth(data) {
        return api.post(`${config.headerUrl}roleAuth/addRoleAuth`,data)
    },
    // 删除角色权限
    deleteRoleAuth(data) {
        return api.post(`${config.headerUrl}roleAuth/deleteRoleAuth`,data)
    },
    // 获取角色功能权限列表
    getRoleModuleList(data) {
        return api.get(`${config.headerUrl}roleAuth/getRoleModuleList`,data)
    },
    // 获取授权模块
    getTypeAndChildren(data) {
        return api.get(`${config.headerUrl}modulelist/getTypeAndChildren`,data)
    },
    /*
    * 用户角色
    * */
    // 新增用户角色
    addStaffrole(data) {
        return api.post(`${config.headerUrl}staffrole/addStaffrole`,data)
    },
    // 删除用户角色
    deleteStaffrole(data) {
        return api.post(`${config.headerUrl}staffrole/deleteStaffrole`,data)
    },
    // 用户角色信息 详情
    getStaffRole(data) {
        return api.get(`${config.headerUrl}staffrole/getStaffRole`,data)
    },
    // 获取用户角色列表
    getStaffRoleList(data) {
        return api.get(`${config.headerUrl}staffrole/getStaffRoleList`,data)
    },
    // 获取用户角色分页列表
    getStaffRoleListByPage(data) {
        return api.get(`${config.headerUrl}staffrole/getStaffRoleListByPage`,data)
    },

    /*
    * 数据字典
    * */
    // 数据字典查询
    getDictionary(data) {
        return api.get(`${config.headerUrl}dictionary/GetDictionary`,data)
    },
    // 新增信息
    addDictionaryInfo(data) {
        return api.post(`${config.headerUrl}dictionary/addInfo`,data)
    },
    // 删除信息
    deleteDictionaryInfo(data) {
        return api.post(`${config.headerUrl}dictionary/deleteInfo`,data)
    },
    // 修改信息
    editDictionaryInfo(data) {
        return api.post(`${config.headerUrl}dictionary/editInfo`,data)
    },
    // 获取详情
    getDictionaryDetail(data) {
        return api.get(`${config.headerUrl}dictionary/getDetail`,data)
    },
    // 获取列表
    getDictionaryList(data) {
        return api.get(`${config.headerUrl}dictionary/getList`,data)
    },
    // 分页列表
    getDictionaryListByPage(data) {
        return api.get(`${config.headerUrl}dictionary/getListByPage`,data)
    },


}

export default rights