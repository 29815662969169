export const iconList = [
    {value:'icon-rili1'},
    {value:'icon-jiashiyuandanganguanli'},
    {value:'icon-yuangongguanli'},
    {value:'icon-a-huaban1fuben23'},
    {value:'icon-daiban_fill'},
    {value:'icon-tupian'},
    {value:'icon-sousuoxiao'},
    {value:'icon-caozuo-caidan'},
    {value:'icon-shebeiguanli1'},
    {value:'icon-caidanshouqi'},
    {value:'icon-caidanzhankai'},
    {value:'icon-chaoshi-solid'},
    {value:'icon-complete-fill'},
    {value:'icon--chakan'},
    {value:'icon-rili'},
    {value:'icon-shanchu'},
    {value:'icon-bianji'},
    {value:'icon-zhijianyuebao-'},
    {value:'icon-yuebao'},
    {value:'icon-shenjigaojingshu'},
    {value:'icon-shebeiguanli'},
    {value:'icon-xitongquanxian'},
    {value:'icon-fork'},
    {value:'icon-pic-line'},
    {value:'icon-24gf-clipboardCheck'},
    {value:'icon-caozuoyuanguanli'},
    {value:'icon-gou'},
    {value:'icon-tupian1'},
    {value:'icon-58xunjianmoban'},
    {value:'icon-guzhangzijian'},
    {value:'icon-zhenggaitongzhidan'},
    {value:'icon-kucunyujing'},
    {value:'icon-anzhuang'},
    {value:'icon-huanbaochufa-5'},
    {value:'icon-fanhui'},
    {value:'icon-shipinjiankong'},
    {value:'icon-shujujianguan'},
]