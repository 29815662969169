<template>
  <div class="pageMain">
    <search-head  @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="类型">
          <el-select size="small" clearable v-model="searchForm.moduleType" placeholder="请选择菜单类型" @change="changeType">
            <el-option label="目录" :value="0"></el-option>
            <el-option label="菜单" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables :data="menuData" :total="menuTotal"
                   noimport="true"
                   noexport="true"
                   title="菜单权限"
                   @selectionChange="selectionMenuChange"
                   @handleCurrentChange="handleCurrentChange"
                   @handleSizeChange="handleSizeChange"
                   @tableDel="tableMenuDel" @tableAdd="tableMenuAdd">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="moduName"
          align="center"
          label="菜单名称">
      </el-table-column>
      <el-table-column
          prop="moduTag"
          align="center"
          label="菜单标识">
      </el-table-column>
      <el-table-column
          prop="moduState"
          align="center"
          width="100"
          label="菜单状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.moduState == 0">启用</el-tag>
          <el-tag v-if="scope.row.moduState == 1" type="danger" >未启用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="moduUrl"
          align="center"
          label="菜单地址"
          width="180">
      </el-table-column>
      <el-table-column
          prop="moduType"
          align="center"
          width="100"
          label="类型">
        <template slot-scope="scope">
          <div v-if="scope.row.moduType == 0">目录</div>
          <div v-if="scope.row.moduType == 1">菜单</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="parentName"
          align="center"
          label="所属菜单">
      </el-table-column>
      <el-table-column
          width="100"
          prop="moduIcon"
          align="center"
          label="菜单图标">
        <template slot-scope="scope">
          <i :class="['icon-select','iconfont',scope.row.moduIcon]"></i>
        </template>
      </el-table-column>
      <el-table-column
          width="100"
          prop="moduSort"
          align="center"
          label="排序">
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <span class="el-icon-edit btn-icon" @click="editMenuItem(scope.row)"></span>
          <span class="el-icon-delete btn-icon" @click="delMenuItem(scope.row)"></span>
        </template>
      </el-table-column>
    </custom-tables>

    <custom-dialog :visible.sync="showDialog" :title="dialogTitle" width="700px" @close="colseDialog" @submit="editsubmit">
      <div>
        <el-form :model="editMenuForm" label-width="80px" class="edit-form" >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="名称" prop="moduName">
                <el-input size="small" v-model="editMenuForm.moduName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标识" prop="moduTag">
                <el-input size="small" v-model="editMenuForm.moduTag" placeholder="请输入标识"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态" prop="moduState">
                <el-select class="block-select" size="small" v-model="editMenuForm.moduState" placeholder="请选择状态">
                  <el-option label="启动" :value="0"></el-option>
                  <el-option label="未启动" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型" prop="moduType">
                <el-select class="block-select" size="small" v-model="editMenuForm.moduType" placeholder="请选择类型">
                  <el-option label="目录" :value="0"></el-option>
                  <el-option label="菜单" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属目录" prop="parentId">
                <el-select class="block-select" size="small" v-model="editMenuForm.parentId" placeholder="请选择所属目录">
                  <el-option v-for="(item, index) in parentList" :key="index" :label="item.moduName" :value="item.moduId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地址" prop="moduUrl">
                <el-input size="small" v-model="editMenuForm.moduUrl" placeholder="请输入地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="图标" prop="moduIcon">
                <el-select class="block-select icon-select" size="small" v-model="editMenuForm.moduIcon" placeholder="请选择图标">
                  <el-option v-for="(item, index) in iconList" :key="index" :label="item.value" :value="item.value">
                    <i :class="['icon-select','iconfont',item.value]"></i> {{item.value}}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="排序" prop="moduSort">
                <el-input size="small" v-model="editMenuForm.moduSort" placeholder="请输入排序"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注" prop="moduNote">
                <el-input size="small" v-model="editMenuForm.moduNote" placeholder="请输入备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

    </custom-dialog>
  </div>
</template>

<script>
import rights from "../../../api/modules/rights";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import CustomDialog from "../../../components/common/CustomDialog";
import {iconList} from '../../../config/menuIconList'

export default {
  name: "rightMenu",
  components: {CustomDialog, SearchHead, CustomTables},
  data() {
    return {
      dialogTitle: '新增公司',
      showDialog: false,
      total: 0,
      menuTotal: 0,
      menuData: [],
      searchForm: {
        currPage: 1,
        pageSize:10
      },
      activeId: 0,
      editForm:{},
      editMenuForm:{},
      parentList:[],
      iconList: iconList
    }
  },
  mounted() {
    this.getMenuInfo()
    this.getParentList()
  },
  methods: {
    getMenuInfo() {
      // 获取菜单信息
      rights.getModuleListByPage(this.searchForm).then(res =>{
        console.log(res)
        this.menuTotal = res.data.total
        this.menuData = res.data.list
      })
    },
    getParentList(){
      // 获取目录的，顶级目录
      rights.getModuleList({moduleType:0}).then(res =>{
        let item = {moduName:'无',moduId:0}
        this.parentList = [item, ...res.data]
      })
    },
    changeType(){

    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getMenuInfo()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getMenuInfo()
    },
    headReset() {
      this.searchForm ={
        pageSize:10,
        currPage:1
      }
      this.getMenuInfo()
    },
    headSearch() {
      this.searchForm.currPage = 1
      this.getMenuInfo()
    },
    tableDel() {

    },
    tableAdd() {
      this.editForm = {}
      this.dialogTitle = '新增目录'
      this.showDialog = true
    },
    selectionChange(val) {
      console.log(val)
    },
    changeItem(item) {
      this.activeId = item
    },
    tableMenuDel() {
      // 删除菜单

    },
    tableMenuAdd() {
      // 获取目录菜单
      this.getParentList()
      this.editMenuForm = {}
      this.dialogTitle = '新增菜单'
      this.showDialog = true
    },
    editMenuItem(item) {
      // 获取目录菜单
      this.getParentList()
      this.editMenuForm = item
      this.dialogTitle = '编辑菜单'
      this.showDialog = true
    },
    delMenuItem(item) {
      // 删除菜单
      this.$confirm(`此操作将永久删除该菜单, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rights.deleteModuleList({moduId:item.moduId}).then(res =>{
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getMenuInfo()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    selectionMenuChange() {
      // 菜单的批量选择
    },
    colseDialog(){
      this.showDialog= false
    },
    editsubmit(){
        if(this.editMenuForm.moduId){
          // 编辑菜单
          rights.editModuleList(this.editMenuForm).then(res =>{
            console.log(res)
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.getMenuInfo()
            this.showDialog= false
          })
        }else {
          // 新增
          rights.addModuleList(this.editMenuForm).then(res =>{
            console.log(res)
            this.$message({
              message: '新增成功',
              type: 'success'
            });
            this.getMenuInfo()
            this.showDialog= false
          })
        }
    },

  }
}
</script>

<style scoped lang="less">
.icon-select{
  &.iconfont{
    font-size: 20px;
  }
}

</style>